import DefaultImg from 'assets/images/default.png';
import WalletSvg from 'assets/svgs/WalletSvg';
import { baseURL } from 'config/api';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const AccountDetails = () => {
    const elementRef = useRef(null);
    const { user } = useSelector(state => state.auth);
    const referralCode = window.origin + '/register/' + user?.referralCode;

    const handleCopy = () => {
        elementRef.current.select();
        document.execCommand('copy');
        toast.success('Link copied.')
    };

    return (
        <div>
            <div className='flex gap-4 lg:flex-row flex-col'>
                <div className='shadow-bg bg-white bg-opacity-20 p-4 flex items-center gap-4 flex-wrap flex-[0.5]'>
                    <div className='shadow-bg rounded-full'>
                        <img 
                        src={user?.image ? (baseURL + user?.image) : DefaultImg} 
                        alt="User" 
                        className='sm:w-[120px] w-[80px] sm:h-[120px] h-[80px] object-cover rounded-full'
                        />
                    </div>
                    <div>
                        <h4 className='font-semibold capitalize text- text-white'>
                            {user?.username}
                        </h4>
                        <p className='text-primary text-[13px] text-gray-200'>
                            {user?.email}
                        </p>
                    </div>
                   
                </div>
                <div className='shadow-bg bg-white bg-opacity-20 p-4 flex items-center gap-4 sm:flex-row flex-col flex-[0.5]'>
                    <div className='flex-1 bg-pure py-4 sm:px-4 px-2 rounded-md flex flex-col gap-3 text-sm sm:w-fit w-full'>
                        <div className='font-semibold flex items-center gap-4 text-white'>
                            <WalletSvg />
                            <h4>My Wallet</h4>
                        </div>
                        <center className='font-semibold text-gray-200'>
                            {user?.wallet?.totalBalance}
                        </center>
                        <div>
                            <button className="btn-secondary py-1 5 px-4 w-full">
                                Withdrawal
                            </button>
                        </div>
                    </div>
                    <div className='flex-1 bg-pure py-4 sm:px-4 px-2 rounded-md flex flex-col gap-3 text-sm sm:w-fit w-full'>
                        <div className='font-semibold flex items-center gap-4 text-white'>
                            <WalletSvg />
                            <h4>Referral Link</h4>
                        </div>
                        <center>
                            <input 
                            ref={elementRef}
                            className=' border-none text-gray-300 outline-none bg-transparent selection:bg-transparent w-full'
                            value={referralCode}
                            />
                        </center>
                        <div>
                            <button 
                            onClick={handleCopy}
                            className="btn-secondary py-1 5 px-4 w-full">
                                Copy Referral Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountDetails