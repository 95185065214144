import React from 'react'
import { useSelector } from 'react-redux'

const PaymentAndExpense = () => {
    const { user } = useSelector(state => state.auth);

    return (
        <div className='text-[15px]'>
        <h3 className='text-lg font-semibold text-gradient'>Payment And Expenses</h3>
        <div className='bg-pure rounded-lg py-4 sm:px-4 px-2 flex flex-col gap-2 mt-2'>
            <h4 className='font-medium text-white'>Payments from referrals</h4>
            <strong className=' font-semibold text-secondary'>
                USD {user?.wallet?.teamCommission}
            </strong>
        </div>
        <div className='bg-pure rounded-lg py-4 sm:px-4 px-2 flex flex-col gap-2 mt-2'>
            <h4 className='font-medium text-white'>Payments used in Campaigns </h4>
            <strong className=' font-semibold text-secondary'>
                USD {user?.wallet?.budgetUsedInCompaigns}
            </strong>
        </div>
    </div>
    )
}

export default PaymentAndExpense