import { debounce } from 'lodash';
import { memo } from 'react';


const Search = ({ fetcher , timeout = 500 , placeholder = 'Search...' }) => {

    const handleInputChange = debounce((event) => {
        const value = event.target.value;
        fetcher(value);
    }, timeout );


    return (
        <div className='flex items-center sm:gap-4 gap-2 border border-primary rounded-lg sm:py-2 py-1.5 sm:px-4 px-2 search-primary'>
            <i className="uil uil-search text-white"></i>
            <input 
            type="text" 
            placeholder={placeholder} 
            className='outline-none border-none bg-transparent text-white'
            onChange={handleInputChange}
            />
        </div>
    )
}

export default memo(Search)