import LogoSvg from 'assets/svgs/LogoSvg';
import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './nav.css';
import HamburgerSvg from 'assets/svgs/HamburgerSvg';
import Sidebar from '../sidebar';
import { useDrawerContext } from 'context/DrawerContext';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/authActions';
import Search from '../Search';
import GlobeSvg from 'assets/svgs/GlobeSvg';
import BellSvg from 'assets/svgs/BellSvg';
import UserSvg from 'assets/svgs/UserSvg';
import UserMenu from './UserMenu';
import useClickOutside from 'utils/clickOutside';
import NotificationsDrop from './NotificationsDrop';

const Navbar = () => {
    const dispatch = useDispatch();
    const { setShowDrawer } = useDrawerContext();
    const { user } = useSelector(state => state.auth);
    
    const userMenuRef = useRef(null);
    const [showUserMenu , setShowUserMenu] = useState(false);

    const notificationsRef = useRef(null);
    const [showNotifications , setShowNotifications] = useState(false);

    const location = useLocation();

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

    // const logoutHandler = () => {
    //     dispatch(logout(setShowAuthPopup))
    // }

    useClickOutside(userMenuRef , () => setShowUserMenu(false));
    useClickOutside(notificationsRef , () => setShowNotifications(false));

    return (
        <nav className='py-4 bg-primary sm:px-12 px-2 text-center text-pure h-[70px] flex items-center fixed top-0 left-0 w-full z-20'>
            <div className='relative w-full flex items-center'>
                <div className='container relative mx-auto flex items-center justify-between '>
                    <div className='flex items-center gap-12'>
                        <Link to='/'>
                            <LogoSvg />
                        </Link>
                        <ul className='nav-list'>
                            <li 
                            className={`${isActive('/' , true) || isActive('dashboard') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to='/'>
                                    Dashboard
                                </Link>
                            </li>
                            <li 
                            className={`${isActive('referral-links') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to='/referral-links'>
                                    Referral Links
                                </Link>
                            </li>
                            <li 
                            className={`${isActive('earnings') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to='/earnings'>
                                    Earnings
                                </Link>
                            </li>
                            <li 
                            className={`${isActive('stats') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to='/compaign/stats'>
                                    Analytics
                                </Link>
                            </li>
                            <li 
                            className={`${isActive('settings') ? 'active' : ''} nav-list-item`}
                            >
                                <Link to='/settings/account'>
                                    settings
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='flex items-center md:gap-8 gap-4'>
                        <div className='md:flex hidden items-center gap-4 border border-gray-200 rounded-full py-1 px-4'>
                            <i className="uil uil-search text-gray-300"></i>
                            <input 
                            type="text" 
                            placeholder='Search...' 
                            className='outline-none border-none bg-transparent text-white'
                            // onChange={handleInputChange}
                            />
                        </div>
                        {/* <div>
                            <GlobeSvg />
                        </div> */}
                        <div className='relative'>
                            <div 
                            onClick={() => setShowNotifications(!showNotifications)}
                            className='cursor-pointer'
                            >
                                <BellSvg />
                            </div>
                            <div
                            ref={notificationsRef}
                            >
                            {
                                showNotifications && 
                                <NotificationsDrop />
                            }
                            </div>
                        </div>
                        <div className='relative'>
                            <div 
                            onClick={() => setShowUserMenu(!showUserMenu)}
                            className='cursor-pointer'
                            >
                                <UserSvg />
                            </div>
                            <div
                            ref={userMenuRef}
                            >
                                {
                                    showUserMenu && 
                                    <UserMenu />
                                }
                            </div>
                        </div>
                        <button 
                        className="lg:hidden block"
                        onClick={() => {
                            setShowDrawer(true);
                        }}
                        >
                            <HamburgerSvg />
                        </button>

                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Navbar