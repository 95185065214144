import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { onMessageListener, requestForToken } from "utils/firebase";
import Layout from "./global/Layout";

const FirebaseNotify = () => {
    const [notification, setNotification] = useState({ title: "", body: "" });
    const notify = () => toast.info(<ToastDisplay />);
    function ToastDisplay() {
        return (
            <div>
                <p>
                    <b>{notification?.title}</b>
                </p>
                <p>{notification?.body}</p>
            </div>
        );
    }
  
    useEffect(() => {
      if (notification?.title) {
        notify();
      }
    }, [notification]);
  
    requestForToken();
  
    onMessageListener()
      .then((payload) => {
        console.log({ payload });
        setNotification({ title: payload?.data?.title, body: payload?.data?.body });
      })
      .catch((err) => console.log("failed: ", err));
  
    return;
};


export default FirebaseNotify;