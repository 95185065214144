import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { ClipLoader } from 'react-spinners';
import fetcher from 'utils/fetcher';

const SubCategorySelector = ({ setSubCategory , category }) => {
    const [categories , setCategories] = useState([]);

    const queryKey = ['fetch-sub-categories' , category]
    const { isLoading , isFetching , data } = useQuery(queryKey , () => {
        return fetcher(`/sub-category/parent/${category}`)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs } } } = data;
            setCategories(docs)
            if(docs?.length > 0 ) setSubCategory(docs[0]?._id);
        }
    }, [data]);

    return (
        isLoading || isFetching
        ? 
            <ClipLoader size={20} color='white' />
        :
        <div className="flex flex-1 flex-col gap-1.5 w-full">
            <label 
            className="font-semibold text-white"
            >
                Sub Category
            </label>
            {
                categories?.length > 0 
                ? 
                    <select
                    className='select-box py-3'
                    onChange={(e) => {
                        setSubCategory(e.target.value)
                    }}
                    >
                        {
                            categories?.map(item => (
                                <option 
                                key={item?._id}
                                value={item?._id}
                                >
                                    {item?.name}
                                </option>
                            ))
                        }
                    </select>
                : 
                    <p className='font-medium text-red-500'>No Sub Category found.</p>
            }
        </div>
    )
}

export default SubCategorySelector