import React from 'react'

const SupportSvg = ({ size = 20 }) => {
    return (
        <div>
            <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 15.7165H14.3667C13.7 15.7165 13.0667 15.9748 12.6 16.4415L11.175 17.8498C10.525 18.4915 9.46667 18.4915 8.81667 17.8498L7.39167 16.4415C6.925 15.9748 6.28333 15.7165 5.625 15.7165H5C3.61667 15.7165 2.5 14.6081 2.5 13.2415V4.1498C2.5 2.78314 3.61667 1.6748 5 1.6748H15C16.3833 1.6748 17.5 2.78314 17.5 4.1498V13.2415C17.5 14.5998 16.3833 15.7165 15 15.7165Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.8335 7.63342C5.8335 6.85842 6.46683 6.22508 7.24183 6.22508C8.01683 6.22508 8.65016 6.85842 8.65016 7.63342C8.65016 9.20008 6.42516 9.36675 5.9335 10.8584C5.8335 11.1667 6.09183 11.4751 6.41683 11.4751H8.65016M13.3668 11.4667V6.70842C13.3673 6.60312 13.3333 6.50056 13.2702 6.41633C13.207 6.3321 13.118 6.27079 13.0168 6.24175C12.9149 6.21297 12.8065 6.21768 12.7075 6.25519C12.6084 6.29269 12.5241 6.36101 12.4668 6.45008C11.8668 7.41675 11.2168 8.51675 10.6502 9.48342C10.5585 9.64175 10.5585 9.85008 10.6502 10.0084C10.7418 10.1667 10.9168 10.2667 11.1085 10.2667H14.1668" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

        </div>
    )
}

export default SupportSvg