import './styles.css';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';
import { useSelector } from 'react-redux';
import LogoPrimary from 'assets/svgs/LogoPrimary';
// import { useDispatch } from 'react-redux';

const Sidebar = () => {
    // const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const sidebarRef = useRef();
    const location = useLocation();
    const { showDrawer , setShowDrawer } = useDrawerContext();
    const [showOrderDropMenu , setShowOrderDropMenu] = useState(false)
    const [showCatDropMenu , setShowCatDropMenu] = useState(false);

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

    useEffect(() => {
        setShowDrawer(false);
    }, [location.pathname])

   

    return (
        <div 
        className='sidebar'
        >
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0  -left-[200%] w-[260px]  overflow-auto pb-4 h-full z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='sidebar-overlay absolute top-0 left-0 w-full h-full'></div>
                <div className='overflow-auto relative z-[9999]'>
                    <div className='flex items-center justify-center border-b pb-6 pt-6'>
                        <Link to='/dashboard' className='text-2xl font-semibold text-gradient'>
                            <LogoPrimary h={30} />                       
                        </Link>
                    </div>
                    <ul className='flex flex-col text-left gap-6 text-grayText mt-6 ml-6 '>
                    <li 
                    className={`${isActive('' , true) ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/'>  
                            Dashboard 
                        </Link>
                    </li>
                    {/* <li 
                    className={`${isActive('categories') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/categories'>  
                            Categories 
                        </Link>
                    </li> */}
                    <li 
                    className={`${isActive('referral-links') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/referral-links'>  
                            Referral Links 
                        </Link>
                    </li>
                  
                    <li 
                    className={`${isActive('earnings') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/earnings'>  
                            Earnings 
                        </Link>
                    </li>
                    <li 
                    className={`${isActive('stats') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/compaign/stats'>  
                            Analytics 
                        </Link>
                    </li>
                    <li 
                    className={`${isActive('my') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/compaign/my'>  
                            My Compaigns 
                        </Link>
                    </li>
                    <li 
                    className={`${isActive('profile') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/manage-account/profile'>  
                            Profile 
                        </Link>
                    </li>
                    <li 
                    className={`${isActive('settings') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link to='/settings/account'>  
                            Settings
                        </Link>
                    </li>
                    <li 
                    className={`text-grayText `}
                    >
                        <Link to='/login'>  
                            Logout
                        </Link>
                    </li>
                </ul>
                </div>
                <div className='mt-12 flex items-center gap-4 pl-6 relative z-[999]'>
                    <Link to='/login'>
                        <button className="btn-secondary py-2 px-4">
                            Register
                        </button>
                    </Link>
                    <Link to='/register'>
                        <button className='btn-primary py-2 px-4 border border-primary'>
                            Login
                        </button>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default Sidebar