import LogoPrimary from 'assets/svgs/LogoPrimary';
import ResetPasswordImg from 'assets/images/resetPassword.png';
import React from 'react'
import ResetPasswordForm from 'components/auth/ResetPasswordForm';

const ResetPassword = () => {
    return (
        <div className='bg-primaryLight w-full min-h-screen max-h-full'>
            <div className='flex justify-between'>
                <div className='lg:flex-[0.65] md:flex-[0.5]  md:flex hidden flex-col items-center justify-center gap-6'>
                   <div className='text-center'>
                        <h1 className='text-2xl font-semibold text-primary'>
                            Reflip - Share, Earn, and Get Paid!
                        </h1>
                        <p 
                        className='text-sm text-gray-600 mt-3 lg:w-1/2 w-[80%] mx-auto'
                        >
                        Reflip is a revolutionary app designed to empower users by providing them with an opportunity to earn cash by simply sharing links to other social media platforms.
                        </p>
                   </div>
                    <div className='flex items-center justify-center'>
                        <img
                        src={ResetPasswordImg}
                        alt='Reset Password'
                        className='w-[80%] '
                        />
                    </div>

                </div>
                <div className='lg:flex-[0.35] md:flex-[0.5] flex-1 px-4 min-h-screen bg-black bg-opacity-10 flex items-center justify-center py-12'>
                    <div className="bg-white bg-opacity-40 border border-primary rounded-md p-4 md:w-[400px] w-full">
                        <div className='flex items-center justify-center'>
                            <LogoPrimary />
                        </div>
                        <div className='mt-4 mb-6 text-center'>
                            <h4 className='text-2xl font-semibold'>
                                Reset Password
                            </h4>
                            <p className='text-primary text-sm font-medium mt-1'>
                                Lorem ipsum dolor sit amet.
                            </p>
                        </div>
                        <div>
                            <ResetPasswordForm />
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;