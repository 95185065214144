import ManageAccountLayout from 'components/manageAccount/ManageAccountLayout'
import AccountInformation from 'components/settings/account/AccountInformation'
import ProfileInfo from 'components/settings/account/ProfileInfo'
import UpdatePasswordForm from 'components/settings/account/UpdatePasswordForm'


const Profile = () => {
    return (
        <ManageAccountLayout>
            <div className='shadow-bg px-4 mt-6 pb-4'>
                <ProfileInfo />
            </div>
            <div className='shadow-bg px-4 mt-6 pb-4'>
                <UpdatePasswordForm />
            </div>
        </ManageAccountLayout>
    )
}

export default Profile