import HamburgerSvg from 'assets/svgs/HamburgerSvg';
import CrossSvg from 'assets/svgs/CrossSvg';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSettingsDrawer } from 'redux/reducers/settingsReducer';

const SettingDrawerBtn = () => {
    const dispatch = useDispatch();
    const { showSettingsDrawer } = useSelector(state => state.setting);

    const drawerClickHandler = () => {
        dispatch(setShowSettingsDrawer(!showSettingsDrawer));
    }

    return (
        <div 
        className={`btn-primary sm:hidden block px-2 py-1 w-fit mb-2 duration-300 ${showSettingsDrawer ? 'ml-[250px] ' : 'ml-0'}`}
        onClick={drawerClickHandler}
        >
            {
                showSettingsDrawer
                ? 
                    <CrossSvg />
                : 
                    <HamburgerSvg />
            }
        </div>
    )
}

export default SettingDrawerBtn