import React from 'react'
import { useSelector } from 'react-redux'

const FuturePayments = () => {
    const { user } = useSelector(state => state.auth);

    return (
        <div>
        <h3 className='text-lg font-semibold text-gradient'>Future Payments</h3>
        <div className='bg-pure rounded-lg py-4 sm:px-4 px-2 flex flex-col gap-2 mt-2 text-[15px]'>
            <h4 className='font-medium text-white'>Payments being cleared</h4>
            <strong className='font-semibold text-secondary'>
                USD {user?.wallet?.pendingAmount}
            </strong>
            {/* <p className='text-sm text-gray-500'>Pre. month <span className='text-green-500 font-medium'>10.0%</span></p> */}
        </div>
        <div className='bg-pure rounded-lg py-4 sm:px-4 px-2 flex flex-col gap-2 mt-2 text-[15px]'>
            <h4 className='font-medium text-white'>Payments of active flip shared</h4>
            <strong className='text-lg font-semibold text-secondary'>
                -
            </strong>
            <p className='text-sm text-gray-300'>coming soon</p>
        </div>
    </div>
    )
}

export default FuturePayments