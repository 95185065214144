import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Axios from 'config/api';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import toastError from 'utils/toastError';

const CompaignClick = () => {
    const { id : compaignId } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sharedBy = searchParams.get('sharedBy');
    const [response , setResponse] = useState(null);
    const [loading , setLoading ] = useState(false);

    useEffect(() => {
        const compaignClickHandler = async () => {
            try {
                setLoading(true);
                const { data : { data : { message , redirect , compaign } } } = await Axios(`/compaign/click/${compaignId}/${sharedBy}`);
                if(redirect) {
                    setLoading(false);
                    return window.location.href = compaign?.compaignUrl;
                }
                setResponse({ message , redirect , compaign });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toastError(error);
            }
        }
        compaignClickHandler();
    }, [])

    console.log({ response });
    return (
        <div className='bg-primaryLight py-2 sm:px-4 px-2'>
            <div 
            className="bg-white bg-opacity-60 rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6"
            >
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    <div 
                    className='flex items-center justify-center w-full min-h-screen font-semibold text-xl text-red-500'
                    >
                        {response?.message}
                    </div>
                }
            </div>
        </div>
    )
}

export default CompaignClick