import CompaignsSvg from 'assets/svgs/CompaignsSvg'
import PromotionsSvg from 'assets/svgs/PromotionsSvg'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import shortNumberFormat from 'utils/ShortNumberFormat'

const Cards = () => {
    const { user } = useSelector(state => state.auth);

    return (
        <div className='w-full grid sm:grid-cols-2 grid-cols-1 gap-2'>
            {/* <div className='rounded-lg p-3 bg-pure'>
                <div className='flex items-center gap-2'>
                    <div className='circle-icon'>
                        <PromotionsSvg />
                    </div>
                    <div>
                        <p className='font-semibold'>338</p>
                        <p className='text-xs text-gray-500'>
                            New Promotions
                        </p>
                    </div>
                </div>
                <div className='text-primary text-sm font-medium mt-3'>
                    <i className="uil uil-angle-right text-lg"></i>
                    <span>Check It Out</span>
                </div>
            </div> */}
            <div className='rounded-lg p-3 bg-pure'>
                <div className='flex items-center gap-2'>
                    <div className='circle-icon'>
                        <CompaignsSvg />
                    </div>
                    <div>
                        <p className='font-semibold text-white'>
                            {user?.flipCompaignsCount}
                        </p>
                        <p className='text-xs text-gray-300'>
                            your Compaigns
                        </p>
                    </div>
                </div>
                <div className='text-primary text-sm font-medium mt-3 text-gradient'>
                <Link to='/compaign/my' >
                    <i className="uil uil-angle-right text-lg "></i>
                    <span>See Compaigns</span>
                </Link>
                </div>
            </div>
            <div className='rounded-lg p-3 bg-pure'>
                <div className='flex items-center gap-2'>
                    <div className='circle-icon'>
                        <PromotionsSvg />
                    </div>
                    <div>
                    <p className='font-semibold text-white'>
                    {shortNumberFormat(user?.flipSharedCount)}
                        </p>
                        <p className='text-xs text-gray-300'>
                            Shared Links
                        </p>
                    </div>
                </div>
                <div className='text-primary text-sm font-medium mt-3 text-gradient'>
                    <Link to='/flip-shared'>
                        <i className="uil uil-angle-right text-lg "></i>
                        <span>See in Details</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Cards