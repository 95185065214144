import DefaultImg from 'assets/images/default.png';
import Input from 'components/global/Input';
import { Link } from 'react-router-dom';

const EditAccountInfo = () => {
    return (
        <div>
            <div className='flex items-center justify-between border-b py-4'>
                <div>
                    <h3 className='text[15px] font-semibold'>Account Information</h3>
                    <small>Manage your account details to keep your account secure.</small>
                </div>
                
            </div>
            <div className='text-sm lg:w-[60%] md:w-[70%] sm:w-[85%] w-full py-8'>
                <div className=' flex justify-between '>
                    <h6 className='font-semibold'>Profile Image</h6>
                    <div>
                        <img 
                        src={DefaultImg}
                        alt='User'
                        className='w-[80px] h-[80px] rounded-full object-cover'
                        />
                    </div>
                </div>
                <div>
                    <Input
                    label='Profile Name'
                    value='JohnDoe@ls'
                    readOnly
                    />
                </div>
                <div className='mt-4 flex flex-col gap-4'>
                    <h6 className='font-semibold'>Personal Details</h6>
                    <div className='flex sm:flex-row flex-col gap-4 items-center mt-4'>
                        <Input
                        label='First Name'
                        value='Shahid'
                        readOnly
                        />
                        <Input
                        label='Last Name'
                        value='Saeed'
                        readOnly
                        />
                    </div>
                    <div className=''>
                        <Input
                        label='Phone Number'
                        value='+92-3219494203'
                        readOnly
                        />
                    </div>
                    <div className='text-sm'>
                        <Input
                        label='Email'
                        value='example123@gmail.com'
                        readOnly
                        />
                    </div>
                    <div className='flex items-center gap-4'>
                        <Input
                        label='Password'
                        value='**********'
                        readOnly
                        />
                        <button className='text-red-500 font-medium'>
                            Change
                        </button>
                    </div>
                </div>
                <div className='flex items-center gap-4 mt-10 justify-end'>
                    <Link to='/settings/account'>
                        <button className="bg-red-500 text-pure rounded-md py-2 px-6 text-sm">
                            Cancel
                        </button>
                    </Link>
                    <button className="bg-green-500 text-pure rounded-md py-2 px-6 text-sm">
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditAccountInfo;