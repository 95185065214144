import Layout from 'components/global/Layout';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/notificationReducer';
import fetcher from 'utils/fetcher';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import moment from 'moment';
import { baseURL } from 'config/api';
import Pagination from 'components/global/pagination';

const Notifications = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const { docs : notifications , currentPage , pages  } = useSelector(state => state.notification);

    const querykey = ['fetch-notifications' , currentPage ]
    const { isLoading , data } = useQuery(querykey , () => {
        return fetcher(`/notification?page=${currentPage}`, user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    return (
        <Layout>
            <div 
            className="bg-pure bg-opacity-60 rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6"
            >
                <div className="gap-2 border-b pb-4">
                    <h3 className='font-semibold text-white'>
                        Notifications
                    </h3>
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    notifications?.length > 0 
                    ? 
                        <>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-6">
                            {
                                notifications?.map(item => (
                                    <div key={item?._id} className='shadow-bg bg-pure p-3 rounded-md flex gap-4'>
                                        <a 
                                        href={item?.imageUrl} 
                                        target='_blank'
                                        className='w-[80px]'
                                        >
                                            <img 
                                            src={baseURL + item?.image} 
                                            alt="" 
                                            className='w-[80px]'
                                            />
                                        </a>
                                        <div className='flex-1'>
                                            <div className='flex sm:items-center justify-between sm:flex-row flex-col'>
                                                <h3 className='text-xl text-primary font-semibold'>
                                                    {item?.name}
                                                </h3>
                                                <div className='sm:text-sm text-xs text-gray-300'>
                                                    <TimeAgo 
                                                    date={new Date(item?.createdAt)} 
                                                    />
                                                </div>
                                            </div>
                                            <p className='text-gray-300 text-sm mt-2'>
                                                {item?.description}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="mt-4">
                            <Pagination
                            pageCount={pages}
                            currentPage={currentPage}
                            setPage={setCurrentPage}
                            />
                        </div>
                        </>
                    :
                        <ItemNotFound message='No Notification found.' />
                }
            </div>
        </Layout>
    )
}

export default Notifications