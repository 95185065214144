import React from 'react'

const SmallClockSvg = () => {
    return (
        <div><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_131_26266)">
        <path d="M13.75 7.5C13.75 10.95 10.95 13.75 7.5 13.75C4.05 13.75 1.25 10.95 1.25 7.5C1.25 4.05 4.05 1.25 7.5 1.25C10.95 1.25 13.75 4.05 13.75 7.5Z" stroke="#34A853" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.8188 9.4876L7.8813 8.33135C7.5438 8.13135 7.2688 7.6501 7.2688 7.25635V4.69385" stroke="#34A853" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_131_26266">
        <rect width="15" height="15" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </div>
    )
}

export default SmallClockSvg