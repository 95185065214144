import React from 'react'
import { useSelector } from 'react-redux'

const StatsInfo = () => {
    const { docs , docsCount } = useSelector(state => state.compaign);


    return (
        <div className='border border-gray-300  py-4 sm:px-4 px-2 grid gap-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 rounded-lg'>
            <div className='border p-4 rounded-lg flex flex-col gap-4 bg-pure'>
                <p className='text-sm font-medium text-white'>Total Compaigns</p>
                <p className='text-gradient font-semibold text-base'>
                    {docsCount || 0}
                </p>
            </div>
            <div className='border p-4 rounded-lg flex flex-col gap-4 bg-pure'>
                <p className='text-sm font-medium text-white'>Total Clicks</p>
                <p className='text-gradient font-semibold text-base'>
                    {docs?.reduce((acc , item) =>  {
                        return item?.totalClicks + acc 
                    } , 0) || 0}
                </p>
            </div>
            <div className='border p-4 rounded-lg flex flex-col gap-4 bg-pure'>
                <p className='text-sm font-medium text-white'>Total Budget Used (USD)</p>
                <p className='text-gradient font-semibold text-base'>
                    ${
                        docs?.reduce((acc , item) => item?.budgetUsedInCompaign + acc , 0) || 0
                    }
                </p>
            </div>
        </div>
    )
}

export default StatsInfo