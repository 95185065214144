import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Banners from 'components/home/Banners'
import Cards from 'components/home/Cards'
import Compaign from 'components/home/Compaign'
import FlipSharedStats from 'components/home/FlipSharedStats'
import Membership from 'components/home/Membership'
import Start from 'components/home/Start'
import Wallet from 'components/home/Wallet'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { setCompaign, setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/compaignReducer'
import fetcher from 'utils/fetcher'


const MyCompaigns = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs : compaigns , currentPage , pages , docsCount , from , to  } = useSelector(state => state.compaign);
    console.log({ compaigns })

    const querykey = ['get-my-compaigns' , currentPage];
    const { isLoading , data , refetch , isRefetching } = useQuery(querykey , () => {
        return fetcher(`/compaign/my?page=${currentPage}&from=${from}&to=${to}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data])


    return (
        <Layout>
            <div className=" sm:p-4 px-2 py-4 min-h-screen mb-6 h-full">
                
                <div>
                    <Banners />
                </div>
                <div className='flex gap-4 mt-4 '>
                    <div className='md:block h-[500px] sticky top-[80px] hidden flex-[0.25] '>
                        <div>
                            <Start />
                        </div>
                        {/* <div className='mt-4'>
                            <Membership />
                        </div> */}
                    </div>
                    <div className='lg:flex-[0.5] md:flex-[0.75] flex-1'>
                        <div className="md:hidden block mt-4">
                            <Start />
                        </div>
                        <div className="lg:hidden block">
                            <Wallet />
                        </div>
                        <div className='lg:mt-0 mt-4'>
                            <Cards />
                        </div>
                        <div className='mt-4'>
                            <Link to='/compaign/create-new'>
                            <button className="btn-primary py-2 px-4 w-full sm:text-base text-sm text-white">
                                Create New Compaign
                            </button>
                            </Link>
                        </div>
                       
                        <div className='mt-4'>
                            <h3 className='heading-sm'>Your Flip Compaigns</h3>
                            <div className='flex flex-col gap-4 mt-4'>
                                {
                                    isLoading 
                                    ? 
                                        <Loader />
                                    : 
                                    compaigns?.length > 0 
                                    ?
                                        compaigns?.map(item => (
                                            <Compaign 
                                            key={item} 
                                            compaign={item}
                                            />
                                        ))  
                                    : 
                                        <ItemNotFound message='No Compaign Found' />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex-[0.25] lg:block hidden h-[650px] sticky top-[80px]'>
                        <div>
                            <Wallet />
                        </div>
                        <div className="mt-4">
                            <FlipSharedStats />
                        </div>
                        
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default MyCompaigns