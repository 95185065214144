import AngleDownSvg from "assets/svgs/AngleDownSvg";
import AngleTopSvg from "assets/svgs/AngleTopSvg";
import { useEffect, useState } from "react";
import Accordion from "react-accordion-comp";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Wallet = () => {
    const [show, setShow] = useState(false);
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <div className="bg-pure rounded-lg">
            <div className="flex items-center justify-between gap-4 border-b py-4 sm:px-4 px-2 cursor-pointer"
            onClick={() => setShow(!show)}
            >
                <h4 className="heading-sm">
                    Your Wallet
                </h4>
                <div 
                className="cursor-pointer"
                >
                    {
                        show
                        ? 
                            <AngleTopSvg size={25} />
                        : 
                            <AngleDownSvg size={25} />
                    }
                </div>
            </div>
            <Accordion isOpen={show}>
                <div className="py-3 sm:px-4 px-2 text-sm flex flex-col gap-6">
                    <div className="flex items-center justify-between gap-4 ">
                        <h6 className="font-medium text-gray-300">
                            Current Balance
                        </h6>
                        <p className="font-semibold text-white ">
                            USD {user?.wallet?.totalBalance}
                        </p>
                    </div>
                    <button className="btn-secondary py-2 px-4">
                        Withdraw
                    </button>
                </div>
            </Accordion>
        </div>
    )
}

export default Wallet