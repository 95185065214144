import DefaultImg from 'assets/images/default.png';
import CopyInput from 'components/global/CopyInput';
import Input from 'components/global/Input';
import { baseURL } from 'config/api';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { updateProfile } from 'redux/actions/authActions';
import fileChangeHandler from 'utils/fileChangeHandler';
import isBase64 from 'utils/isBase64';


const ProfileInfo = () => {
    const dispatch = useDispatch();
    const imgRef = useRef(null);
    const { user , updateLoading } = useSelector(state => state.auth);

    const [image , setImage] = useState(user?.image ? baseURL + user?.image : DefaultImg);
    
    const saveHandler = async () => {
        if(!isBase64(image)) return toast.info('You have not made any changes.');
        dispatch(updateProfile({ image }));
    }

    return (
        <div className=''>
            <div className='border-b py-4 gap-4'>
                <h3 className='text[15px] font-semibold text-white'>
                    Account Information
                </h3>
                <small className='text-gray-300'>
                    Manage your account details to keep your account secure.
                </small>
            </div>
            <div>
                <div 
                className='flex items-center justify-center flex-col gap-4 mt-4'
                >
                    <div className='w-[100px] h-[100px] bg-white border rounded-full flex items-center justify-center'>
                    <img 
                    src={image}
                    alt='User'
                    className='w-[80px] h-[80px] rounded-full object-cover'
                    />
                    </div>
                    <div>
                        <input 
                        type="file" 
                        ref={imgRef}
                        onChange={(e) => {
                            console.log('FileChange running');
                            fileChangeHandler(e , setImage)
                        }}
                        accept='image/*'
                        hidden
                        />
                        <button 
                        className="btn-secondary py-1.5 px-4 text-sm"
                        onClick={() => imgRef.current.click()}
                        type='button'
                        >
                            Upload Image
                        </button>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col gap-4 items-center mt-8 input-group'>
                    <Input
                    label='Username'
                    value={user?.username}
                    readOnly
                    />
                    <Input
                    label='Email'
                    value={user?.email}
                    readOnly
                    />
                </div>
                <div className='flex md:flex-row flex-col gap-4 items-center mt-4 input-group'>
                    <Input
                    label='Phone'
                    value={user?.phone}
                    readOnly
                    />
                    <CopyInput
                    label='Referral Code'
                    value={user?.referralCode}
                    readOnly
                    />
                </div>
                <div className='mt-6 flex justify-end'>
                    <button 
                    className="btn-primary py-2 px-12 text-white"
                    onClick={saveHandler}
                    disabled={updateLoading || !isBase64(image)}
                    >
                        {
                            updateLoading 
                            ? 
                                <ClipLoader size={20} color='white' />
                            : 
                                'Save'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo