import GoogleSvg from 'assets/svgs/GoogleSvg';
import MobileSvg from 'assets/svgs/MobileSvg';
import NameInput from 'components/global/NameInput'
import PasswordInput from 'components/global/PasswordInput';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { login } from 'redux/actions/authActions';

const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading } = useSelector(state => state.auth);

    const [loginData , setLoginData] = useState({
        identifier : '' , 
        password : ''
    });
    

    const submitHandler = e => {
        e.preventDefault();
        const { identifier , password } = loginData;
        const data = { identifier , password , role : 'user' };
        dispatch(login(data , navigate));
    }

    return (
        <div>
            <form 
            className='flex flex-col gap-4'
            onSubmit={submitHandler}
            >
                <NameInput
                label='Email / Username'
                placeholder='Enter email or username'
                data={loginData}
                setData={setLoginData}
                name='identifier'
                minLength={3}
                maxLength={50}
                required
                />
                <PasswordInput
                label='Password'
                placeholder='Enter password'
                data={loginData}
                setData={setLoginData}
                name='password'
                minLength={6}
                maxLength={50}
                required
                />
                <div className='text-red-500 text-center'>
                    <Link to='/forgot-password' >
                        Forgot Password
                    </Link>
                </div>
                <div>
                    <button 
                    className="btn-primary py-2 px-4 w-full text-white"
                    disabled={loading}
                    >
                        {
                            loading 
                            ? 
                                <ClipLoader size={20} color='white' />
                            : 
                                'LOGIN'
                        }
                    </button>
                </div>
            </form>
            <div className='my-6 text-center text-white'>
            -------------- or ---------------
            </div>
            <div className='flex sm:gap-2 gap-4 justify-between sm:flex-row flex-col'>
                <div>
                    <button className='flex items-center justify-center gap-2 py-2.5 px-3 bg-white rounded-md border border-transparent hover:border-primary text-sm font-medium w-full'>
                        <MobileSvg />
                        <span>Login With Phone</span>
                    </button>
                </div>
                <div className=''>
                    <button className='flex items-center justify-center gap-2 py-2.5 px-3 bg-white rounded-md border border-transparent hover:border-primary text-sm font-medium w-full'>
                        <GoogleSvg />
                        <span>Login With Google</span>
                    </button>
                </div>
            </div>
            <div>
            
            </div>
            <div className="mt-6 text-sm text-center text-white">
                Don't have an account <Link 
                to='/register'
                className='text-red-500 font-medium hover:underline'
                >Register Here</Link>
            </div>


        </div>


    )
}

export default LoginForm