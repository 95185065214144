import React from 'react'

const PromotionsSvg = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.9919 7.07116L18.8801 6.80618L16.8746 2.05279C16.8546 2.00543 16.7609 1.86266 16.612 1.76989C16.489 1.69326 16.3346 1.65078 16.1146 1.74359C15.9958 1.79373 15.8948 1.88307 15.8309 2.00126L15.8307 2.00161L14.0792 5.23249L14.0791 5.23268C13.1449 6.95421 11.638 8.32095 9.83378 9.0822L3.86367 11.6011C3.01892 11.9575 2.62316 12.931 2.97958 13.7757L4.23904 16.7608C4.59546 17.6056 5.56891 18.0013 6.41367 17.6449L7.46154 17.2028L7.98735 16.9809L8.13814 17.5313C8.22725 17.8566 8.33559 18.1774 8.46862 18.4927C8.95982 19.6569 9.69484 20.6426 10.5878 21.4253C10.73 21.5499 10.9581 21.5882 11.1707 21.4985L13.4804 20.524C13.9329 20.3331 13.9498 19.7253 13.5682 19.5066C12.6846 19.0003 11.9527 18.2112 11.527 17.2023C11.3622 16.8116 11.2618 16.4145 11.2094 16.0211L11.159 15.6427L11.5106 15.4944L12.3838 15.126C14.188 14.3647 16.2185 14.239 18.1033 14.7712L18.1034 14.7712L21.6399 15.771L21.64 15.771C21.7667 15.8069 21.9018 15.7986 22.0232 15.7475L18.9919 7.07116ZM18.9919 7.07116L19.2772 7.10775M18.9919 7.07116L19.2772 7.10775M19.2772 7.10775C19.8498 7.18117 20.3619 7.53995 20.5983 8.10028C20.8347 8.66055 20.7344 9.27777 20.3872 9.73966L20.2144 9.96952M19.2772 7.10775L20.2144 9.96952M20.2144 9.96952L20.3262 10.2345M20.2144 9.96952L20.3262 10.2345M20.3262 10.2345L22.3318 14.9878C22.3551 15.0432 22.3904 15.2092 22.3529 15.3765M20.3262 10.2345L22.3529 15.3765M22.3529 15.3765C22.322 15.5144 22.2447 15.6539 22.0233 15.7475L22.3529 15.3765ZM19.7932 14.0439L20.7943 14.3268L20.3899 13.3683L16.6792 4.57364L16.2748 3.61515L15.779 4.5297L15.0982 5.78543C14.04 7.7373 12.3293 9.2882 10.2847 10.1509L9.82401 10.3453L10.0184 10.8059L11.2778 13.791L11.4722 14.2517L11.9329 14.0573C13.9775 13.1946 16.2821 13.0514 18.4187 13.6553L19.7932 14.0439Z" stroke="white"/>
</svg>

    )
}

export default PromotionsSvg