import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/actions/authActions";

// export const baseURL = 'http://localhost:4545';
export const baseURL = 'https://reflip.raabtastore.com';


const Axios = axios.create({
    baseURL : `${baseURL}/api` ,
});


export const useApi = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    Axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                dispatch(logout(navigate , false)); 
                localStorage.setItem('user' , null)
            }
            return Promise.reject(error);
        }
    );
    return Axios;
};

export default Axios;