import React from 'react'

const CompaignsSvg = () => {
    return (
        <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.92181 15.8979L3.92438 15.8986L13.0898 18.192C13.0904 18.1922 13.091 18.1923 13.0915 18.1925C13.4065 18.2694 13.7362 18.2689 14.0447 18.1927C14.0451 18.1926 14.0454 18.1925 14.0458 18.1925L23.2191 15.8991C23.2194 15.899 23.2197 15.899 23.2201 15.8989C23.4911 15.8297 23.6797 15.5849 23.6797 15.3114V8.75652L18.8305 10.1405C18.5729 10.2177 18.3104 10.2513 18.0556 10.2513C17.0772 10.2513 16.158 9.73301 15.6568 8.89497C15.6565 8.89448 15.6562 8.89398 15.6559 8.89349L13.5734 5.44097L11.4933 8.8948C11.4931 8.89511 11.4929 8.89541 11.4927 8.89572L11.4926 8.89586L10.8502 8.50893C10.4812 9.12387 9.80689 9.50555 9.09017 9.50555C8.89933 9.50555 8.70848 9.48011 8.52612 9.42498L3.92181 15.8979ZM3.92181 15.8979C3.65665 15.8326 3.46601 15.5918 3.46601 15.3114V8.75722L8.31494 10.1446L3.92181 15.8979ZM18.0822 7.38566L14.8094 1.95703L24.235 0.754803L26.3713 5.01914L18.0822 7.38566ZM2.91045 0.755309L12.3354 1.95697L9.05927 7.38143L0.775711 5.01649L2.91045 0.755309Z" stroke="white" strokeWidth="1.5"/>
</svg>

    )
}

export default CompaignsSvg