import CopyInput from 'components/global/CopyInput'
import Input from 'components/global/Input'
import Layout from 'components/global/Layout'
import Search from 'components/global/Search'
import ReferralLinksTable from 'components/referralLinks/ReferralLinksTable'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage, setDocs, setDocsCount, setKeyword, setPages } from 'redux/reducers/teamReducer'
import fetcher from 'utils/fetcher'

const ReferralLinks = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const url = window.location.origin + `/register?ref_code=${user?.referralCode}`;

    const { currentPage , keyword } = useSelector(state => state.team)

    const queryKey = ['fetch-team' , currentPage , keyword ]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user/my-team?keyword=${keyword}&page=${currentPage}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    const searchFetcher = (value) => {
        dispatch(setKeyword(value));
    }

    return (
        <Layout>
            <div 
            className=" sm:p-4 px-2 py-4 min-h-screen mb-6"
            >
                <div className="flex sm:flex-row flex-col sm:items-center sm:gap-2 border-b pb-4">
                    <h3 className='font-semibold text-white'>
                    Referral Link 
                    </h3>
                    <p className='sm:block hidden'>|</p>
                    <p className='text-sm font-semibold text-gray-300'>Share your referral link to earn more</p>
                </div>
                <div className='flex items-center gap-4 sm:flex-row flex-col mt-6 input-group'>
                    <Input
                    label='Your Name'
                    value={user?.username}
                    readOnly
                    />
                    <CopyInput
                    label='Your Referral Link'
                    value={url}
                    />
                </div>
                <div className='mt-12'>
                    <div className='flex sm:items-center justify-between gap-4 sm:flex-row flex-col'>
                        <h6 className='heading-sm'>Referral Link</h6>
                        <div className='flex sm:flex-row flex-col  sm:items-center gap-4 '>
                            <div>
                                <Search 
                                fetcher={searchFetcher}
                                />
                            </div>
                            <button className="btn-primary py-2 sm:px-4 px-3 sm:text-sm text-xs text-white">
                                Export CSV
                            </button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <ReferralLinksTable 
                        isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ReferralLinks