// Key_pair =>  BBlo4QFM2sPSBYP6sORkmkyoiJwRZAMsazOD24YBGKCczpP0j0Ss6dTDELEEEUbL0RKVUDA6cYA7AV7ViEdFiYw


import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBFB3vJulGC83NAssSoBgcW-_bTl9nJSBY",
    authDomain: "fir-course-989a4.firebaseapp.com",
    databaseURL: "https://fir-course-989a4-default-rtdb.firebaseio.com",
    projectId: "fir-course-989a4",
    storageBucket: "fir-course-989a4.appspot.com",
    messagingSenderId: "600540233468",
    appId: "1:600540233468:web:214d0a53caf292dbf1e179",
    measurementId: "G-DT6EBYVMWD"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();


export const requestForToken = () => {
    return getToken(messaging, {
        vapidKey: `BBlo4QFM2sPSBYP6sORkmkyoiJwRZAMsazOD24YBGKCczpP0j0Ss6dTDELEEEUbL0RKVUDA6cYA7AV7ViEdFiYw`,
    })
    .then((currentToken) => {
    if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
    } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
    }
    })
    .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});