import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { ClipLoader } from 'react-spinners';
import fetcher from 'utils/fetcher';

const CategorySelector = ({ setCategory }) => {
    const [categories , setCategories] = useState([]);

    const { isLoading , data : catData } = useQuery('fetch-categories' , () => {
        return fetcher(`/category/total`)
    });

    useEffect(() => {
        if(catData) {
            const { data : { data : { docs } } } = catData;
            setCategories(docs);   
            if(docs?.length > 0) setCategory(docs[0]?._id)
        }
    }, [catData]);

    return (
        isLoading 
        ? 
            <ClipLoader size={20} />
        :
        <div className="flex flex-1 flex-col gap-1.5 w-full">
            <label 
            className="font-semibold text-white"
            >
                Category
            </label>
            <select
            className='select-box py-3'
            onChange={(e) => {
                setCategory(e.target.value)
            }}
            >
                {
                    categories?.map(item => (
                        <option 
                        key={item?._id}
                        value={item?._id}
                        >
                            {item?.name}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}

export default CategorySelector