import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { setFrom, setKeyword, setTo } from 'redux/reducers/compaignReducer';

const StatsFilter = ({ isRefetching , refetch }) => {
    const dispatch = useDispatch();
    
    const { from , to , keyword , docs } = useSelector(state => state.compaign);

    const resetFilterHandler = () => {
        dispatch(setFrom(''));
        dispatch(setTo(''));
        dispatch(setKeyword(''));
        setTimeout(() => {
            refetch();
        }, 200)
    }


    return (
        <div className='border border-gray-300  rounded-md p-4'>
            <div className='grid gap-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1'>
                <div className="input-group">
                    <label>From</label>
                    <input 
                    type="date" 
                    placeholder='Select From Date'
                    onChange={(e) => {
                        dispatch(setFrom(e.target.value));
                    }}
                    value={from}
                    />
                </div>
                <div className="input-group">
                    <label>To</label>
                    <input 
                    type="date" 
                    placeholder='Select To Date'
                    onChange={(e) => {
                        dispatch(setTo(e.target.value));
                    }}
                    value={to}
                    className='bg-pure text-white '
                    />
                </div>
                <div className="input-group">
                    <label>
                        Compaign Name
                    </label>
                    <input 
                    type="text" 
                    placeholder='Enter compaign name'
                    onChange={e => {
                        dispatch(setKeyword(e.target.value))
                    }}
                    value={keyword}
                    />
                </div>
            </div>
            <div className='flex items-end gap-4 justify-end mt-6 sm:text-sm text-xs'>
                <button 
                className="btn-secondary py-2 px-4"
                onClick={resetFilterHandler}
                >
                    Reset
                </button>
                <button 
                className="btn-primary py-2 px-4 border text-white"
                disabled={!from && !to && !keyword || isRefetching}
                onClick={() => refetch()}
                >
                    {
                        isRefetching 
                        ? 
                            <ClipLoader size={20} color='white' />
                        : 
                            'Search'
                    }
                </button>
            </div>
        </div>
    )
}

export default StatsFilter