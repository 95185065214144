import Input from 'components/global/Input';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const ForgotPasswordForm = () => {
    const [identifier, setIdentifier] = useState('');

    return (
        <div>
            <form className='flex flex-col gap-4'>
                <Input
                label='Email / Username'
                placeholder='Enter email or username'
                value={identifier}
                setValue={setIdentifier}
                />
                <Link to='/reset-password' className='mt-4'>
                    <button className="btn-primary py-2 px-4 w-full">
                        SUBMIT
                    </button>
                </Link>
            </form>
            <div className="mt-6 text-sm text-center mb-4">
                Remember Password? <Link 
                to='/login'
                className='text-red-500 font-medium hover:underline'
                >Back to Login</Link>
            </div>


        </div>


    )
}

export default ForgotPasswordForm