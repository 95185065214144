import ItemNotFound from 'components/global/ItemNotFound';
import ItemStatus from 'components/global/ItemStatus';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { setCurrentPage } from 'redux/reducers/compaignReducer';


const StatsReportTable = () => {
    const { currentPage , docs , pages } = useSelector(state => state.compaign);

    return (
        <div className=" shadow-bg overflow-x-auto rounded-lg">
            <Table className="w-full table-auto overflow-x-auto ">
                <Thead className="border-b text-sm bg-primary text-pure">
                    <Tr className='bg-primary text-white'>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Date
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Compaign
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Clicks
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Pay Per Click
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Budget Used (USD)
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center ">
                            Start Date
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center ">
                            End Date
                        </Th>
                        <Th scope="col" className=" font-medium px-6 py-4 text-center">
                            Status
                        </Th>
                    </Tr>
                </Thead>
                <Tbody className='text-sm'>
                {
                    docs?.length > 0 
                    ?
                    docs?.map((item) => (
                        <Tr
                        key={item} 
                        className="bg-pure border-b transition duration-300 ease-in-out"
                        >
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {moment(item?.createdAt).format('DD MMM YYYY')}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.name}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.totalClicks}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.payPerClick}
                            </Td>
                            <Td className=" text-white  px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                {item?.budgetUsedInCompaign}
                            </Td>
                            <Td className="text-white px-6 py-4 whitespace-nowrap sm:text-center text-right ">
                                {moment(item?.startDate).format('DD MMM YYYY')}
                            </Td>
                            <Td className="text-white px-6 py-4 whitespace-nowrap text-primary sm:text-center text-right"
                            >
                                {moment(item?.endDate).format('DD MMM YYYY')}
                            </Td>
                            <Td className="px-6 py-4 whitespace-nowrap text-primary sm:text-center text-right"
                            >
                                <ItemStatus status={item?.status} />
                            </Td>
                        </Tr>
                        ))
                    : 
                        <tr>
                            <td colSpan={8}>
                                <ItemNotFound message='No compaign found.' />
                            </td>
                        </tr>
                }
                </Tbody>
            </Table>
            {
                docs?.length > 0 && 
                <Pagination 
                currentPage={currentPage}
                pageCount={pages}
                setPage={setCurrentPage}
                />
            }
        </div>
    )
}

export default StatsReportTable;