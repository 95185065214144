import React from 'react'

const MobileSvg = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_131_22155)">
<path d="M1.74655 30.7522C1.61578 30.7072 1.48341 30.6663 1.35425 30.6174C0.952919 30.4666 0.607961 30.1956 0.366561 29.8413C0.125161 29.4871 -0.000890326 29.0669 0.00565044 28.6382C0.00137173 26.2849 0.00137173 23.9316 0.00565044 21.5783V2.30408C0.00565044 0.842369 0.852035 0 2.31937 0H15.3833C16.8089 0 17.6674 0.854403 17.6674 2.2744V28.1873C17.6674 29.7052 17.3192 30.2178 15.9249 30.7546L1.74655 30.7522ZM16.7047 15.4066V2.28403C16.7047 1.38389 16.2819 0.961104 15.3825 0.961104H2.28487C1.38393 0.961104 0.961943 1.38309 0.961943 2.28242V28.4673C0.961943 29.3667 1.38554 29.7911 2.28326 29.7911H15.3777C16.2771 29.7911 16.7014 29.3667 16.7014 28.4689L16.7047 15.4066Z" fill="black"/>
<path d="M8.82962 29.1943C8.61703 29.1943 8.40654 29.1524 8.21019 29.0709C8.01384 28.9894 7.83551 28.8699 7.68541 28.7194C7.53531 28.5688 7.4164 28.3901 7.33548 28.1936C7.25457 27.997 7.21324 27.7863 7.21387 27.5738C7.22049 27.1503 7.39166 26.746 7.69115 26.4465C7.99063 26.147 8.39491 25.9759 8.81839 25.9692C9.2477 25.969 9.65978 26.1381 9.96515 26.4399C10.2705 26.7417 10.4445 27.1517 10.4494 27.581C10.4483 28.0096 10.2771 28.4204 9.9734 28.7229C9.66968 29.0254 9.25829 29.195 8.82962 29.1943ZM8.8224 28.2316C8.99386 28.234 9.15941 28.1691 9.28348 28.0507C9.40754 27.9323 9.48021 27.77 9.48587 27.5986C9.48673 27.4222 9.41763 27.2527 9.29373 27.1271C9.16982 27.0016 9.0012 26.9302 8.82481 26.9287C8.65348 26.9333 8.4905 27.0037 8.36976 27.1253C8.24902 27.2469 8.17983 27.4104 8.17658 27.5818C8.17803 27.753 8.24641 27.9168 8.3671 28.0383C8.48779 28.1597 8.65121 28.2291 8.8224 28.2316Z" fill="black"/>
<path d="M8.80166 3.06289C8.31068 3.06289 7.8205 3.06289 7.33032 3.06289C6.99257 3.06289 6.79201 2.87998 6.78719 2.59277C6.78238 2.30556 6.98375 2.11142 7.31428 2.105C8.32512 2.09912 9.33624 2.09912 10.3476 2.105C10.6781 2.105 10.8803 2.30476 10.8755 2.59197C10.8707 2.87918 10.6685 3.05969 10.3332 3.06289C9.82294 3.0661 9.3119 3.06289 8.80166 3.06289Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_131_22155">
<rect width="17.6625" height="30.7522" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}

export default MobileSvg