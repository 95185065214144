import React from 'react'

const LinkOpenSvg = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_131_21120)">
            <path d="M6.5 5.5L10.6 1.4M11 3.4V1H8.6M5.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11H7.5C10 11 11 10 11 7.5V6.5" stroke="gray" strokeOpacity="0.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_131_21120">
            <rect width="12" height="12" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    )
}

export default LinkOpenSvg