import AccountSvg from 'assets/svgs/AccountSvg'
import NotificationSvg from 'assets/svgs/NotificationSvg'
import PaymentSvg from 'assets/svgs/PaymentSvg'
import SecuritySvg from 'assets/svgs/SecuritySvg'
import SupportSvg from 'assets/svgs/SupportSvg'
import './styles.css';
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const SettingsSidebar = () => {
    const location = useLocation();

    const { showSettingsDrawer } = useSelector(state => state.setting);
    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }


    return (
        <div
        className={`s-sidebar ${showSettingsDrawer ? 'show' : ''}`}>
            <h3 className='font-semibold text-white'>Settings</h3>
            <div className='mt-6'>
                <ul className='s-sidebar-list'>
                    <li className={`${isActive('account') ? 'active' : ''} s-sidebar-list-item`}>
                        <Link to='/settings/account'>
                            <AccountSvg />
                            <p>Account</p>
                        </Link>
                    </li>
                    <li className={`${isActive('payment') ? 'active' : ''} s-sidebar-list-item`}>
                        <Link to='/settings/payment'>
                            <PaymentSvg />
                            <p>Payment</p>
                        </Link>
                    </li>
                    {/* <li className={`${isActive('security') ? 'active' : ''} s-sidebar-list-item`}>
                        <Link to='/settings/security'>
                            <SecuritySvg />
                            <p>Security & Privacy</p>
                        </Link>
                        
                    </li> */}
                    {/* <li className={`${isActive('notifications') ? 'active' : ''} s-sidebar-list-item`}>
                        <Link to='/settings/notifications'>
                            <NotificationSvg />
                            <p>Notifications</p>
                        </Link>
                    </li> */}
                    <li className={`${isActive('support') ? 'active' : ''} s-sidebar-list-item`}>
                        <Link to='/settings/support'>
                            <SupportSvg />
                            <p>Help & Support</p>
                        </Link>
                        
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default SettingsSidebar