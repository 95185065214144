import { createSlice } from "@reduxjs/toolkit";

const compaignSlice = createSlice({
    name : 'compaign' ,
    initialState : {
        docs : [] ,
        compaign : '' ,
        docDetails : null ,
        loading : false , 
        createLoading : false , 
        updateLoading : false , 
        deleteLoading : false ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        status : '' ,
        duration : '' ,
        keyword : '' ,
        category : '' ,
        filter : '' ,
        targetCountry : '' , 
        showSharePopup : false , 
        from : '' , 
        to : '' , 
        fetchAgain : false 
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setCompaign (state , action) {
            state.compaign = action.payload;
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        addDoc (state , action) {
            state.docs = state.docs.unshift(action.payload);
        } ,
        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        } ,
        removeDoc (state , action) {
            state.docs = state.docs.filter(i => i._id !== action.payload);
        } , 
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setCreateLoading (state , action ) {
            state.createLoading = action.payload
        } ,
        setDeleteLoading (state , action ) {
            state.deleteLoading = action.payload
        } ,
        setBlockLoading (state , action ) {
            state.blockLoading = action.payload
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
        setStatus (state , action) {
            state.status = action.payload;
        } ,
        setKeyword (state , action) {
            state.keyword = action.payload;
        } ,
        setDuration (state , action) {
            state.duration = action.payload;
        } ,
        setFrom (state , action) {
            state.from = action.payload;
        } ,
        setTo (state , action) {
            state.to = action.payload;
        } ,
        setFilter (state , action) {
            state.filter = action.payload;
        } ,
        setCategory (state , action) {
            state.category = action.payload;
        } ,
        setTargetCountry (state , action) {
            state.targetCountry = action.payload;
        } , 
        setShowSharePopup (state , action) {
            state.showSharePopup = action.payload;
        } ,
        setFetchAgain (state , action) {
            state.fetchAgain = action.payload;
        }
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setCreateLoading , setUpdateLoading , setDeleteLoading , setCurrentPage , setPages , updateDoc , removeDoc , setDocsCount , addDoc , setStatus , setKeyword , setDuration , setBlockLoading , setCategory , setTargetCountry , setShowSharePopup , setFilter , setFrom , setTo , setFetchAgain , setCompaign
} = compaignSlice.actions;

export default compaignSlice.reducer;