import React, { useState, useMemo, memo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector({ setCountry , placeholder = 'Country'}) {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = country => {
        setCountry(country.value);
        setValue(country);
    }

    return <Select 
    options={options} 
    value={value} 
    onChange={changeHandler} 
    className='outline-none w-full'
    inputId='select-box-input'
    placeholder={placeholder}
    />
}

export default memo(CountrySelector)