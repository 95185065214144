import CategorySelector from 'components/compaigns/CategorySelector';
import CountrySelector from 'components/compaigns/CountrySelector';
import LatestFlipCompaigns from 'components/compaigns/LatestFlipCompaigns';
import SubCategorySelector from 'components/compaigns/SubCategorySelector';
import DateRangePicker from 'components/global/DateRangePicker';
import Layout from 'components/global/Layout'
import MultiFileInput from 'components/global/MultiFileInput';
import NameInput from 'components/global/NameInput';
import Textarea from 'components/global/Textarea';
import Compaign from 'components/home/Compaign';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { compaign_createDoc } from 'redux/actions/compaignActions';

const CreateCompaign = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [images , setImages] = useState([]);
    const [category , setCategory] = useState('');
    const [subCategory , setSubCategory] = useState('');
    const [targetCountry , setTargetCountry] = useState('');
    const { createLoading } = useSelector(state => state.compaign);

    const [compaignData , setCompaignData] = useState({
        name : '' ,
        description : '' ,
        compaignUrl : '' , 
        payPerClick : '' ,
        compaignBudget : '' ,
        startDate : new Date() ,
        endDate : ''
    });

    const submitHandler = (e) => {
        e.preventDefault();
        const data = {...compaignData , category , subCategory , images , targetCountry };
        if(images?.length === 0 ) {
            return toast.error('Compaign images are required.')
        }
        dispatch(compaign_createDoc(data , navigate));
    }


    return (
        <Layout>
            <div className="bg-pure bg-opacity-60 rounded-lg sm:p-4 px-2 py-4 min-h-screen mb-6">
                <div className="flex items-center gap-2 border-b pb-4">
                    <h3 className='font-semibold text-white'>Create New Flip Compaign</h3>
                    <p>|</p>
                    <p className='text-sm text-gray-300 sm:block hidden'>View and filter your clicks, conversions, sales, and earnings.</p>
                </div>
                <div className='mt-6 border-b pb-6'>
                    <form 
                    className='flex flex-col gap-4'
                    onSubmit={submitHandler}
                    >
                        <div className="md:w-[49.4%] sm:w-[80%] w-full">
                            <NameInput
                            label='Compaign Name'
                            placeholder='Name'
                            name='name'
                            data={compaignData}
                            setData={setCompaignData}
                            required
                            />
                        </div>
                        <div className="flex items-center gap-4 sm:flex-row flex-col">
                            <NameInput
                            type='date'
                            label='Start Date'
                            placeholder='Select Start Date'
                            name='startDate'
                            data={compaignData}
                            setData={setCompaignData}
                            required
                            />
                            <NameInput
                            type='date'
                            label='End Date'
                            placeholder='Select End Date'
                            name='endDate'
                            data={compaignData}
                            setData={setCompaignData}
                            required
                            />
                        </div>
                        <div className="flex items-center gap-4 sm:flex-row flex-col">
                            <NameInput
                            label='Compaign URL'
                            placeholder='Enter compaign URL'
                            name='compaignUrl'
                            data={compaignData}
                            setData={setCompaignData}
                            required
                            />
                            <NameInput
                            type='number'
                            label='Pay Per Click'
                            placeholder='Per click amount'
                            name='payPerClick'
                            data={compaignData}
                            setData={setCompaignData}
                            required
                            />
                        </div>
                        <Textarea
                        label='Description'
                        placeholder='Write description...'
                        name='description'
                        data={compaignData}
                        setData={setCompaignData}
                        required
                        />
                        <MultiFileInput
                        label='Compaign Images'
                        images={images}
                        setImages={setImages}
                        />
                        <div className="flex items-center gap-4 sm:flex-row flex-col">
                            <div className='flex-1 w-full'>
                                <CategorySelector 
                                setCategory={setCategory}
                                />
                            </div>
                            {
                                category && 
                                <div className="flex-1">
                                    <SubCategorySelector
                                    setSubCategory={setSubCategory}
                                    category={category}
                                    />
                                </div>
                            }
                        </div>
                        <div className="flex items-center gap-4 sm:flex-row flex-col">
                            <div className="flex flex-1 flex-col gap-1.5 w-full">
                                <label 
                                className="font-semibold text-white"
                                >
                                    Target Region
                                </label>
                                <CountrySelector 
                                setCountry={setTargetCountry}
                               
                                />
                            </div>
                            <div className="flex flex-1 flex-col gap-1.5 w-full">
                            <NameInput
                            label='Compaign Budget'
                            placeholder='How much you want to spent on this compaign'
                            name='compaignBudget'
                            data={compaignData}
                            setData={setCompaignData}
                            />
                            </div>
                        </div>
                        <div className="mt-6">
                            <button 
                            className="btn-primary py-2 px-8 text-white"
                            disabled={createLoading}
                            >
                                {
                                    createLoading
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    : 
                                        'Create'
                                }
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                    <LatestFlipCompaigns />
                </div>
            </div>
        </Layout>
    )
}

export default CreateCompaign