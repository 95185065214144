import StatsFilter from 'components/compaigns/StatsFilter'
import StatsInfo from 'components/compaigns/StatsInfo'
import StatsReport from 'components/compaigns/StatsReport'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setCurrentPage, setDocs, setDocsCount , setPages } from 'redux/reducers/compaignReducer'
import fetcher from 'utils/fetcher'

const CompaignStats = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , keyword , from , to , filter } = useSelector(state => state.compaign);

    const querykey = ['fetch-my-compaings-stats' , currentPage  ];

    const { isLoading , data , refetch , isRefetching } = useQuery(querykey , () => {
        return fetcher(`/compaign/my?keyword=${keyword}&filter=${filter}&page=${currentPage}&from=${from}&to=${to}` , user );
    });  

    useEffect(() => {
        if (data) {
            const { data : { data : { docs , currentPage , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(currentPage));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);



    return (
        <Layout>
            <div 
            className=" sm:p-4 px-2 py-4 min-h-screen mb-6"
            >
                <div className="flex items-center gap-2 border-b pb-4">
                    <h3 className='font-semibold text-white'>Flip Compaign Stats</h3>
                </div>
                <div className='flex items-center justify-between gap-4 bg-primary py-3 px-4 rounded-md text-pure sm:text-sm text-xs mt-6 sm:flex-row flex-col'>
                    <div className='flex sm:items-center gap-2 sm:flex-row flex-col'>
                        <i className="uil uil-comment-dots text-lg text-white"></i>
                        <div className='text-white flex sm:items-center sm:flex-row flex-col gap-1'>
                            <p>Fill in your billing details to ensure prompt payments.</p>
                            <Link to='#' className='underline'>Update details</Link>
                        </div>
                    </div>
                    <i className="uil uil-times text-lg cursor-pointer text-white"></i>
                </div>
                <div className="mt-6">
                    <div>
                        <StatsFilter 
                        refetch={refetch} 
                        isRefetching={isRefetching}
                        />
                    </div>
                    <div className="mt-6">
                        <StatsInfo />
                    </div>
                {
                    isLoading
                    ? 
                        <Loader />
                    : 
                        <div className="mt-10">
                            <StatsReport />
                        </div>    
                   
                }
                </div>
            </div>
        </Layout>
    ) 
}

export default CompaignStats