import AvailableFunds from 'components/earnings/AvailableFunds'
import FuturePayments from 'components/earnings/FuturePayments'
import PaymentAndExpense from 'components/earnings/PaymentAndExpense'
import WalletHistoryTable from 'components/earnings/WalletHistoryTable'
import Layout from 'components/global/Layout'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/walletHistoryReducer'
import fetcher from 'utils/fetcher'

const Earnings = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { currentPage , docs } = useSelector(state => state.walletHistory);

    const queryKey = ['fetch-wallet-histories' , currentPage ];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/wallet-history/my` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docsCount));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
        }
    }, [data])
    


    return (
        <Layout>
        <div 
        className=" sm:p-4 px-2 py-4 min-h-screen mb-6"
        >
            <div className="gap-2 border-b pb-4">
                <h3 className='font-semibold text-white'>
                    Earning Overview
                </h3>
            </div>
            <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-8'>
                <div>
                    <AvailableFunds />
                </div>
                <div>
                    <FuturePayments />
                </div>
                <div>
                    <PaymentAndExpense />
                </div>
            </div>
            <div className="mt-8">
                <WalletHistoryTable 
                isLoading={isLoading}
                />
            </div>
            
        </div>
        </Layout>
    )
}

export default Earnings