import WalletHistoryTable from 'components/earnings/WalletHistoryTable';
import Loader from 'components/global/Loader';
import ManageAccountLayout from 'components/manageAccount/ManageAccountLayout';
import PaymentFilters from 'components/manageAccount/paymentHistory/PaymentFilters';
import WalletDetails from 'components/manageAccount/paymentHistory/WalletDetails';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/walletHistoryReducer';
import fetcher from 'utils/fetcher';

const PaymentHistory = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { currentPage , docs } = useSelector(state => state.walletHistory);

    const queryKey = ['fetch-wallet-histories' , currentPage ];
    const { isLoading , data , refetch} = useQuery(queryKey , () => {
        return fetcher(`/wallet-history/my` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docsCount));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
        }
    }, [data])

    return (
        <ManageAccountLayout>
            <div className='shadow-bg px-4 mt-6 pb-4'>
                <div className='border-b py-4'>
                    <h3 className='font-semibold text-[15px] text-white'>
                        Payment History
                    </h3>
                </div>
                {/* <div className='mt-6'>
                    <WalletDetails />
                </div> */}
                <div className="mt-8">
                    <PaymentFilters 
                    refetch={refetch}
                    />
                </div>
                <div className="mt-8">
                    {
                        isLoading 
                        ? 
                            <Loader />
                        :
                            <WalletHistoryTable />
                    }
                </div>
            </div>
        </ManageAccountLayout>
    )
}

export default PaymentHistory;