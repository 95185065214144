import Navbar from "./navbar"

const Layout = ({ children }) => {
    return (
        <div>
            <Navbar />
            <div className='relative mt-[70px] '>
                <div className='bg-primaryLight py-2 sm:px-4 px-2 h-full'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout