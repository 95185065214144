import React from 'react'

const LogoSvg = () => {
    return (
        <svg width="110" height="35" viewBox="0 0 150 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_131_17739)">
<path d="M50.128 28.4549L44.4569 18.6569H43.7435V28.4549H37.6118V3.26904H47.5543C49.5139 3.26904 51.1936 3.63026 52.5933 4.34366C53.993 5.05707 55.0405 6.00526 55.7539 7.17921C56.4583 8.35317 56.8105 9.61743 56.8105 10.981C56.8105 12.4439 56.368 13.8617 55.483 15.2524C54.598 16.6431 53.1532 17.6184 51.1394 18.1963L57.3072 28.4549H50.128ZM43.7435 13.9972H47.5453C48.4754 13.9972 49.2159 13.7263 49.7487 13.1754C50.2815 12.6246 50.5524 11.8931 50.5524 10.99C50.5524 10.087 50.2815 9.37361 49.7306 8.80469C49.1798 8.22674 48.4483 7.9468 47.5453 7.9468H43.7435V14.0062V13.9972Z" fill="white"/>
<path d="M80.1632 20.454H66.3828C66.5002 21.339 66.8976 22.0614 67.5658 22.6213C68.2341 23.1812 69.0739 23.4611 70.0763 23.4611C70.8439 23.4611 71.4128 23.3437 71.8011 23.118C72.1804 22.8922 72.5867 22.5129 73.0202 21.9892H79.6575C79.0796 23.9939 77.9327 25.6194 76.217 26.8475C74.4922 28.0757 72.4513 28.6988 70.0853 28.6988C68.1709 28.6988 66.4551 28.2834 64.938 27.4435C63.4209 26.6037 62.2379 25.4027 61.389 23.8404C60.5402 22.2781 60.1157 20.463 60.1157 18.4041C60.1157 16.3451 60.5402 14.5481 61.389 13.0039C62.2379 11.4597 63.4209 10.2767 64.9199 9.45494C66.428 8.63317 68.1347 8.21777 70.0492 8.21777C71.9636 8.21777 73.7336 8.61511 75.2868 9.41882C76.8401 10.2225 78.0682 11.3965 78.9622 12.9497C79.8562 14.5029 80.3077 16.3542 80.3077 18.5124C80.3077 19.2078 80.2626 19.849 80.1632 20.454ZM73.9232 16.6161C73.8239 15.6859 73.4175 14.9274 72.7041 14.3404C71.9907 13.7534 71.0967 13.4644 70.0492 13.4644C69.1371 13.4644 68.3515 13.7624 67.6832 14.3404C67.015 14.9274 66.5905 15.6859 66.428 16.6161H73.9232Z" fill="white"/>
<path d="M100.644 3.26904V7.93777H90.3856V13.356H98.0614V18.0157H90.3856V28.4549H84.2539V3.26904H100.653H100.644Z" fill="white"/>
<path d="M111.02 1.90527V28.4547H104.889V1.90527H111.02Z" fill="white"/>
<path d="M116.899 1.0385C117.594 0.343156 118.443 0 119.445 0C120.448 0 121.297 0.343156 121.992 1.0385C122.687 1.73384 123.031 2.59173 123.031 3.6212C123.031 4.65067 122.687 5.47243 121.992 6.16777C121.297 6.86312 120.448 7.20627 119.445 7.20627C118.443 7.20627 117.594 6.86312 116.899 6.16777C116.204 5.47243 115.86 4.62357 115.86 3.6212C115.86 2.61882 116.204 1.73384 116.899 1.0385ZM122.534 8.42538V28.4458H116.402V8.43441H122.534V8.42538Z" fill="white"/>
<path d="M144.514 9.52718C145.914 10.4031 147.015 11.6132 147.837 13.1664C148.65 14.7197 149.056 16.4896 149.056 18.4763C149.056 20.463 148.65 22.233 147.837 23.7862C147.024 25.3394 145.914 26.5495 144.514 27.4074C143.114 28.2653 141.588 28.6988 139.936 28.6988C137.443 28.6988 135.484 27.6422 134.048 25.5381V37.9911H127.916V8.4345H134.048V11.0533C135.411 9.16597 137.371 8.21777 139.936 8.21777C141.588 8.21777 143.114 8.65123 144.514 9.52718ZM136.314 14.0875C135.646 14.4578 135.104 15.0086 134.68 15.7401C134.264 16.4716 134.057 17.3746 134.057 18.4492C134.057 19.5239 134.264 20.4359 134.68 21.1764C135.095 21.9169 135.646 22.4768 136.332 22.8471C137.01 23.2173 137.714 23.4069 138.427 23.4069C139.141 23.4069 139.872 23.2263 140.541 22.8471C141.209 22.4768 141.751 21.9259 142.175 21.1945C142.59 20.463 142.807 19.56 142.807 18.4854C142.807 17.4107 142.599 16.4987 142.175 15.7582C141.76 15.0177 141.209 14.4578 140.522 14.0875C139.836 13.7173 139.141 13.5277 138.427 13.5277C137.714 13.5277 136.983 13.7173 136.314 14.0875Z" fill="white"/>
<path d="M6.60136 9.10262C6.24917 8.75947 5.68928 8.7685 5.3371 9.10262C4.55145 9.87924 3.33235 11.1796 3.18786 11.3331C0.207821 14.4757 -0.893891 19.4876 0.794798 23.5152C1.90554 26.1611 4.2083 28.0665 6.19499 30.0532C7.97398 31.8322 9.74394 33.6021 11.5229 35.3811L12.6246 36.4829C12.9768 36.835 13.5548 36.835 13.916 36.4829L16.282 34.1169C16.6342 33.7647 16.6342 33.1868 16.282 32.8255L7.90174 24.4453C6.45687 23.0004 5.30098 21.3479 5.29195 19.1896C5.29195 18.3227 5.49965 17.4829 5.87892 16.7243C6.14984 16.1734 6.89936 16.0741 7.33282 16.5076L9.27436 18.4491C9.62655 18.8013 10.2045 18.8013 10.5657 18.4491L12.742 16.2728C13.1033 15.9116 13.1033 15.3246 12.733 14.9724C11.3062 13.5998 7.96495 10.403 6.60136 9.10262Z" fill="white"/>
<path d="M19.8491 29.051C20.1923 29.4031 20.7522 29.4122 21.1134 29.0781C21.9261 28.3195 23.1723 27.0552 23.3168 26.9017C26.3691 23.8404 27.5972 18.8646 26.0169 14.7919C24.9784 12.1189 22.7208 10.1503 20.7793 8.11845C19.0545 6.2943 17.3206 4.47919 15.5958 2.65505L14.5212 1.52624C14.178 1.16503 13.6001 1.14697 13.2298 1.49012L10.8007 3.79288C10.4394 4.13603 10.4214 4.71398 10.7645 5.08423L18.928 13.6722C20.3368 15.1531 21.4475 16.8328 21.4024 18.9911C21.3843 19.858 21.1495 20.6888 20.7522 21.4383C20.4632 21.9801 19.7227 22.0614 19.2983 21.6189L17.4019 19.6232C17.0587 19.262 16.4808 19.2439 16.1105 19.5871L13.88 21.7092C13.5098 22.0614 13.5008 22.6484 13.862 23.0096C15.2527 24.4184 18.5126 27.6964 19.8401 29.0419L19.8491 29.051Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_131_17739">
<rect width="149.047" height="38" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}

export default LogoSvg